<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Настройки</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="elevation-0">
            <v-form v-model="valid" ref="form">
              <!-- <v-row>

                <v-col cols="12"> -->
              <div class="settings-edit-head">
                 <v-subheader class="px-0"
                >Аватар</v-subheader
              >
                <div
                  @click="$refs.loadImage.click()"
                  class="image-preview"
                  :class="settingsLoading ? 'settingsLoading' : ''"
                  v-bind:style="{
                    backgroundImage: 'url(' + imageData + ')',
                  }"
                >
                  <v-icon v-if="!settingsLoading"
                    >$vuetify.icons.file_upload</v-icon
                  >
                  <input
                    ref="loadImage"
                    type="file"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="uploadeImage"
                  />
                  <div v-if="settingsLoading" class="avatar_lodaing">
                    <v-progress-circular
                      color="#fff"
                      absolute
                      indeterminate
                      size="32"
                    ></v-progress-circular>
                    <div class="text">Загрузка</div>
                  </div>
                </div>


                 <v-subheader class="px-0"
                >Обложка</v-subheader
              >
                <div
                  @click="$refs.loadCover.click()"
                  class="cover-preview"
                  :class="settingsLoading ? 'settingsLoading' : ''"
                  v-bind:style="{
                    backgroundImage: 'url(' + imageCoverData + ')',
                  }"
                >
                  <v-icon v-if="!settingsLoading"
                    >$vuetify.icons.file_upload</v-icon
                  >
                  <input
                    ref="loadCover"
                    type="file"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="uploadeCover"
                  />
                  <div v-if="settingsLoading" class="avatar_lodaing">
                    <v-progress-circular
                      color="#fff"
                      absolute
                      indeterminate
                      size="32"
                    ></v-progress-circular>
                    <div class="text">Загрузка</div>
                  </div>
                </div>

                <v-btn
                  v-if="currentUser"
                  class="mt-4"
                  color="primary"
                  dark
                  :to="{
                    name: 'UserShow',
                    params: { userName: currentUser.name },
                  }"
                  >Просмотр профиля</v-btn
                >
              </div>

              <v-text-field
                v-model="email"
                label="E-mail"
                disabled
              ></v-text-field>
              <v-text-field
                :disabled="oldPseudonym ? true : false"
                v-model="pseudonym"
                name="inputpseudonym"
                label="Имя Фамилия(псевдоним)"
                hint=" Имя Фамилия (Псевдоним) вводится один раз. Изменить потом будет нельзя."
              ></v-text-field>

              <v-menu
                ref="birthday_menu"
                v-model="birthday_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="birthday_date"
                    label="Дата рождения"
                    prepend-icon="$vuetify.icons.calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="ru-ru"
                  ref="picker"
                  :first-day-of-week="1"
                  v-model="birthday_date"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="birthday_menu = false"
                    >Закрыть</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.birthday_menu.save(birthday_date)"
                    >Принять</v-btn
                  >
                </v-date-picker>
              </v-menu>
              <!-- <v-select
                    v-if="genders && genders.length"
                    v-model="gender"
                    :items="genders"
                    item-text="title"
                    item-value="id"
                    label="Укажите Ваш пол"
                  ></v-select> -->
              <v-textarea
                name="inputAbout"
                label="О себе"
                value
                v-model="about"
                :rules="aboutRules"
              ></v-textarea>
              <!-- Вконтакте автора, Инстаграм, Телеграм, Литрес, Лабиринт, Лайфлиб -->
              <v-subheader v-if="isAuthor" class="px-0"
                >Вы в социальных сетях</v-subheader
              >

              <v-text-field
                v-if="isAuthor"
                v-model="vkProfile"
                name="inputVk"
                label="Вконтакте"
                hint="Ссылка на ваш профиль Вконтакте."
              ></v-text-field>
              <!-- <v-text-field
                v-if="isAuthor"
                v-model="instProfile"
                name="inputInst"
                label="Инстаграм"
                hint="Ссылка на ваш профиль Инстаграм."
              ></v-text-field> -->
              <v-text-field
                v-if="isAuthor"
                v-model="tgProfile"
                name="inputTg"
                label="Телеграм"
                hint="Ссылка на ваш профиль Телеграм."
              ></v-text-field>
              <v-text-field
                v-if="isAuthor"
                v-model="litresProfile"
                name="inputLitres"
                label="Литрес"
                hint="Ссылка на ваш профиль Литрес."
              ></v-text-field>
              <v-text-field
                v-if="isAuthor"
                v-model="labirintProfile"
                name="inputLabirint"
                label="Лабиринт"
                hint="Ссылка на ваш профиль Лабиринт."
              ></v-text-field>

              <v-text-field
                v-if="isAuthor"
                v-model="livelibProfile"
                name="inputLivelib"
                label="Лайфлиб"
                hint="Ссылка на ваш профиль Лайфлиб."
              ></v-text-field>

              <v-checkbox
                v-model="email_notify"
                :false-value="0"
                :true-value="1"
                label="Получать уведомления по E-mail о выходе продолжений книг из вашей библиотеки и новинок авторов, на которых вы подписаны. Если вы Автор, то вам будут приходить также уведомления о новых комментариях к вашим книгам."
              ></v-checkbox>

              <social-settings></social-settings>
              <v-btn
                class="mt-2"
                dark
                color="primary"
                :loading="settingsLoading"
                @click="submit"
                >Обновить</v-btn
              >
              <!-- </v-col>
              </v-row>
              <v-row>
                <v-col> -->
              <div class="mt-8">
                <v-btn
                  v-if="currentUser"
                  color="error"
                  dark
                  :to="{
                    name: 'CabinetSettingsPassword',
                  }"
                  >Смена пароля</v-btn
                >
              </div>
              <!-- </v-col>
              </v-row> -->
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import SocialSettings from "./SocialSettings.vue";
import { mapGetters } from "vuex";
export default {
  components: { SocialSettings },
  data: () => ({
    name: "",
    pseudonym: "",
    oldPseudonym: "",
    email: "",
    gender: 0,
    genders: [
      { id: 0, title: "Не указан" },
      { id: 1, title: "Мужской" },
      { id: 2, title: "Женский" },
    ],
    about: "",
    author: 0,
    email_notify: 0,
    imageData: "",
    imageCoverData: "",
    image: "",
    cover: "",
    valid: true,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    // nameRules: [
    //   v => !!v || "Поле обязательно",
    //   v => v.length >= 3 || "Минимум 3 символа"
    // ],
    aboutRules: [
      // v => !!v || 'Поле обязательно',
      (v) =>
        (!!v === v.length >= 10) === v.length <= 400 ||
        "Минимум 10 символов, максимум 400 символов",
    ],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Размер файла не должен превышать 2 MB!",
    ],
    settingsLoading: false,
    birthday_menu: false,
    birthday_date: null,

    vkProfile: "",
    instProfile: "",
    tgProfile: "",
    litresProfile: "",
    labirintProfile: "",
    livelibProfile: "",
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    updateData() {
      if (this.currentUser) {
        this.name = this.currentUser.name;
        this.pseudonym = this.currentUser.pseudonym
          ? this.currentUser.pseudonym
          : "";
        this.oldPseudonym = this.pseudonym;

        this.about = this.currentUser.about ? this.currentUser.about : "";
        //this.gender = +this.currentUser.gender;

        this.email = this.currentUser.email;
        this.imageData = this.currentUser.avatar_url;
        this.imageCoverData = this.currentUser.cover_url;
        this.author = +this.currentUser.author;
        this.email_notify = +this.currentUser.email_notify;
        this.birthday_date =
          this.currentUser.birthday_date &&
          this.currentUser.birthday_date != "null"
            ? this.currentUser.birthday_date
            : new Date().toISOString().substr(0, 10);

        this.vkProfile = this.clearNull(this.currentUser.vkProfile);
        this.instProfile = this.clearNull(this.currentUser.instProfile);
        this.tgProfile = this.clearNull(this.currentUser.tgProfile);
        this.litresProfile = this.clearNull(this.currentUser.litresProfile);
        this.labirintProfile = this.clearNull(this.currentUser.labirintProfile);
        this.livelibProfile = this.clearNull(this.currentUser.livelibProfile);
      }
    },
    clearNull(string) {
      let newString;
      if (string == null || string == "null") {
        newString = "";
      } else {
        newString = string;
      }
      return newString;
    },
    uploadeImage(event) {
      //Превью изображения, данные на сервер не отправляем
      if (this.settingsLoading) {
        return;
      }
      let input = event.target;
      if (input.files && input.files[0]) {
        this.image = input.files[0];
        this.submit();
      }
    },
    uploadeCover(event) {
      //Превью изображения, данные на сервер не отправляем
      if (this.settingsLoading) {
        return;
      }
      let input = event.target;
      if (input.files && input.files[0]) {
        this.cover = input.files[0];
        this.submit();
      }
    },
    submit() {
      if (!this.validate()) return;
      let app = this;
      app.settingsLoading = true;
      let data = {
        about: app.about,
        // gender: app.gender,
        email_notify: app.email_notify,
        birthday_date: app.birthday_date,
      };

      if (this.isAuthor) {
        data.vkProfile = app.vkProfile;
        data.instProfile = app.instProfile;
        data.tgProfile = app.tgProfile;
        data.litresProfile = app.litresProfile;
        data.labirintProfile = app.labirintProfile;
        data.livelibProfile = app.livelibProfile;
      }

      if (!app.oldPseudonym) {
        data.pseudonym = app.pseudonym;
      }

      if (app.image) {
        data.avatar = app.image;
      }
      if (app.cover) {
        data.cover = app.cover;
      }

      let formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }

      formData.append("_method", "PUT"); //костыль для laravel
      this.$store
        .dispatch("account/updateAccount", formData)
        .then(() => {
          app.settingsLoading = false;
        })
        .catch(() => {
          app.settingsLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser", "loadPage"]),
    pseudonymRules() {
      const rules = [];
      if (this.author) {
        const rule = (v) => !!v || "Поле обязательно";
        const rule2 = (v) => (v && v.length >= 3) || "Минимум 3 символа";
        rules.push(rule);
        rules.push(rule2);
      }

      return rules;
    },
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    },
  },
  watch: {
    currentUser() {
      if (this.currentUser) {
        this.updateData();
      }
    },
    birthday_menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>
