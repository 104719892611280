<template>

  <div v-if="currentUser" class="social-links mb-4">
    <v-subheader class="px-0">Добавить социальные сети. Вы сможете быстро авторизоваться через социальную сеть</v-subheader>
    <!-- <v-btn color="#1877f2" :loading="loading" :disabled="currentUser.activatedSociality.facebook" :dark="!currentUser.activatedSociality.facebook" class="soc-link" @click="AuthProvider('facebook')"><v-icon left>$vuetify.icons.facebook</v-icon>Facebook</v-btn> -->
    <v-btn color="#5181b8" :loading="loading" :disabled="currentUser.activatedSociality.vkontakte" :dark="!currentUser.activatedSociality.vkontakte" class="soc-link" @click="AuthProvider('vkontakte')"><v-icon left>$vuetify.icons.vk</v-icon>Vkontakte</v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading:false,
    };
  },
  methods: {
    AuthProvider(provider) {
      var self = this;
      this.loading=true;
      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.accountSocialAdd(provider, response);
        });
    },
    accountSocialAdd(provider, response) {
      this.$store
        .dispatch("account/socialAdd", {
          provider: provider,
          response: response,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading=false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  
};
</script>